import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

// @ts-ignore
const VideoPage = lazy(() => import('./VideoRoom'));

function Routes() {
  return (
    <Suspense fallback={'<div>Loading...</div>'}>
      <Switch>
	<Route path='/' component={VideoPage} />
        <Route path='/video-room' component={VideoPage} exact />
        <Redirect to='/404' />
      </Switch>
    </Suspense>
  );
}

export default Routes;
